import { LibraryResource, LibraryResourceId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { DistributiveOmit } from "../../utils/object-keys";

export async function libraryResource__client__deleteLibraryResource(p: { libraryResourceId: LibraryResourceId }) {
  const { ollieFirestoreV2: h, app } = getUniversalHelpers();

  const resource = await h.LibraryResource.getDoc(p.libraryResourceId);

  if (!resource) {
    return;
  }

  const urisToDelete = Object.values(resource.items ?? {}).reduce((acc, item) => {
    if (item.type === "image") {
      acc.push(item.imageUri);
    } else if (item.type === "video") {
      acc.push(item.videoUri);
      acc.push(item.videoThumbnailUri);
    }
    return acc;
  }, [] as string[]);
  try {
    await Promise.all(
      urisToDelete.map(uri => {
        return app.storage().refFromURL(uri).delete();
      })
    );
  } catch (e) {
    // Do nothing, don't stop deleting the resource because one of the assets can't be deleted
  }

  await h.LibraryResource.delete({ id: p.libraryResourceId });
}

// i18n certified - complete
