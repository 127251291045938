import { getUniversalHelpers } from "../../helpers";

const DIRECTORY = "library_resources/";

export function libraryResource__client__uploadVideo(p: { name: string; blob: any }) {
  const { app } = getUniversalHelpers();
  const task = app
    .storage()
    .ref()
    .child(DIRECTORY + Date.now() + p.name)
    .put(p.blob);

  return task;
}

// i18n certified - complete
