import { LibraryResource } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { DistributiveOmit } from "../../utils/object-keys";

export async function libraryResource__client__addLibraryResource(p: {
  libraryResource: DistributiveOmit<LibraryResource, "id" | "createdAtMS" | "updatedAtMS">;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const id = h.LibraryResource.generateId();
  const nowMS = Date.now();
  await h.LibraryResource.add({ doc: _.cloneDeep({ ...p.libraryResource, createdAtMS: nowMS, updatedAtMS: nowMS, id }) });
}

// i18n certified - complete
