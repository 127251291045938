import { OrgId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function libraryResource__client__getLibraryResourcesForOrg(p: { orgId: OrgId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return firestoreLiftQuerySubToBifrostSub(h.LibraryResource.querySubscription({ where: [{ orgId: ["==", p.orgId] }] }));
}

// i18n certified - complete
