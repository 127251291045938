import { TeamId } from "@ollie-sports/models";
import { createBifrostSubscription } from "@ollie-sports/react-bifrost";
import { getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function libraryResource__client__getLibraryResourcesForTeam(p: { teamId: TeamId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftQuerySubToBifrostSub(h.LibraryResource.querySubscription({ where: [{ teamId: ["==", p.teamId] }] }));
}

// i18n certified - complete
