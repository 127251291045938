import { getUniversalHelpers } from "../../helpers";

const DIRECTORY = "library_resources/";

export async function libraryResource__client__deleteVideoAndImageUris(p: { urisToDelete: string[] }) {
  const { app } = getUniversalHelpers();

  await Promise.all(
    p.urisToDelete.map(uri => {
      return app.storage().refFromURL(uri).delete();
    })
  );
}

// i18n certified - complete
