import { getUniversalHelpers } from "../../helpers";

const DIRECTORY = "library_resources/";

export function libraryResource__client__uploadImage(p: { name: string; blob: any }) {
  const { app } = getUniversalHelpers();
  const ref = app
    .storage()
    .ref()
    .child(DIRECTORY + Date.now() + p.name);
  const task = ref.put(p.blob);
  return task;
}

// i18n certified - complete
